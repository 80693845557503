<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-chip outline square
        v-for="(equipment, idx) in inspections"
        :key="idx"
        id="chip"
        :color="equipment.color"
        text-color="white"
        icon="date_range"
        size="18px"
        :selected.sync="equipment.selected"
        :label="equipment.year"
        :title="equipment.year"
        v-model="equipment.check"
        @update:selected="state => selectedEquipment(state, equipment, idx)"
      >
      </q-chip>
      <!-- <q-chip
        clickable
        color="orange"
        text-color="white"
        icon="add"
        label="추가"
        title="추가"
        @click="addProcess"
      /> -->
    </div>
    <div class="col-12">
      <template>
        <component
          :is="component"
          :selectedRow.sync="selectedRow"
        />
      </template>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-target-equipment',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        year: '',
        equipmentCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      inspections: [],
      selectedRow: {
        check: true,
        color: "primary",
        equipmentMaintainMonthList: [],
        selected: true,
        year: '',
      },
      component: null,
      getUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.min.equipment.maintain.getYear.url;
      // code setting
      // list setting
      this.getList();
      
    },
    getList() {
      this.$http.url = this.$format(this.getUrl, this.popupParam.equipmentCd);
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.inspections = _result.data;
        this.$_.forEach(this.inspections, _item => {
          _item.check = true;
          _item.selected = false;
          _item.color = 'grey-6';

        })
        // 여기부터 하면됨
        this.selectedEquipment(true, this.inspections[0], 0)
        this.component = () => import('./equipmentRecordByYear.vue');
      },);
    },
    selectedEquipment(state, equipment, idx) {
      if (state) {
        this.inspections.forEach((item, index) => {
          if (idx !== index) {
            item.selected = false;
            item.color = 'grey-6'
          } else {
            item.selected = true;
            item.color = 'orange'
            this.selectedRow = equipment;
          }
        })
      } else {
        this.$_.forEach(this.inspections, item => {
          item.selected = false;
          item.color = 'grey-6'
        })
      }
    },
  }
};
</script>
<style>
#chip {
  font-size: 15px !important;
  border-left: 20px;
  margin: 4px;
}
</style>
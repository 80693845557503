var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      _vm._l(_vm.inspections, function (equipment, idx) {
        return _c("q-chip", {
          key: idx,
          attrs: {
            outline: "",
            square: "",
            id: "chip",
            color: equipment.color,
            "text-color": "white",
            icon: "date_range",
            size: "18px",
            selected: equipment.selected,
            label: equipment.year,
            title: equipment.year,
          },
          on: {
            "update:selected": [
              function ($event) {
                return _vm.$set(equipment, "selected", $event)
              },
              (state) => _vm.selectedEquipment(state, equipment, idx),
            ],
          },
          model: {
            value: equipment.check,
            callback: function ($$v) {
              _vm.$set(equipment, "check", $$v)
            },
            expression: "equipment.check",
          },
        })
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        [
          _c(_vm.component, {
            tag: "component",
            attrs: { selectedRow: _vm.selectedRow },
            on: {
              "update:selectedRow": function ($event) {
                _vm.selectedRow = $event
              },
              "update:selected-row": function ($event) {
                _vm.selectedRow = $event
              },
            },
          }),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }